import React from 'react';

import isArray from 'lodash/isArray';

class PIQ extends React.Component {

	drawField = (input, showItemNumber, sectionNumber, rowNumber, fieldNumber, inputNumber) => {

		const labelFor = 'inputFor'+[sectionNumber, rowNumber, fieldNumber, inputNumber].join('-');

		return [
			<label key={0} htmlFor={labelFor} className={'col-md-'+input.labelWidth+' col-form-label'}>
				<div className='d-flex'>
					<div className='mr-2 flex-grow-0'>
						{showItemNumber && (sectionNumber+1)+'.'+(rowNumber+1)+' '}
					</div>
					<div className='flex-grow-1'>{input.label}</div>
					{(input.type === 'yes-no-notes') && <div className='d-flex flex-grow-0 justify-content-center align-items-center'>
						<input type='radio' name={labelFor} value='1' className='form-control mx-2' />
							Yes
						<input type='radio' name={labelFor} value='0' className='form-control mx-2' />
							no
					</div>}
				</div>
			</label>,
			<div key={1} className={'col-md-'+input.inputWidth}>
				{!input.type && <input type='text' className='form-control' id={labelFor} onChange={(e) => {

				}} />}
				{input.type === 'select' && <select className='form-control' id={labelFor} onChange={(e) => {

				}}>
					{input.options.map((opt, id) => {
						return <option key={id} value={opt.value}>
							{opt.name}
						</option>;
					})}
				</select>}
				{(input.type === 'textarea' || input.type === 'yes-no-notes') && <textarea className='form-control' id={labelFor} onChange={(e) => {

				}} />}
			</div>
		];

	}

	render() {

		const config = [
			{
				name: 'PROPERTY TO BE SOLD',
				header: "Throughout this form this property may be referred to as ‘the property’, ‘this property’ or ‘your property’",
				rows: [
					{
						header: 'Property Address',
						fields: [
							[
								{
									id: 'address1',
									inputWidth: 9,
									label: 'Address Line 1',
									labelWidth: 3,
								},
							],
							[
								{
									id: 'address2',
									inputWidth: 9,
									label: 'Address Line 2',
									labelWidth: 3,
								},
							],
							[
								{
									id: 'town',
									inputWidth: 9,
									label: 'Town',
									labelWidth: 3,
								},
							],
							[
								{
									id: 'county',
									inputWidth: 3,
									label: 'County',
									labelWidth: 3,
								},
								{
									id: 'postcode',
									inputWidth: 3,
									label: 'Postcode',
									labelWidth: 3,
								},
							],
						],
					},
					{
						field: {
							id: 'council_tax',
							inputWidth: 6,
							label: 'Postcode',
							labelWidth: 6,
						},
					},
					{
						field: {
							id: 'uprn',
							inputWidth: 6,
							label: 'UPRN',
							labelWidth: 6,
						},
					},
					{
						field: {
							id: 'title',
							inputWidth: 6,
							label: 'What is the title to the property?',
							labelWidth: 6,
							options: [
								{
									name: 'Freehold',
									value: 'freehold',
								},
								{
									name: 'Managed freehold',
									value: 'managed_freehold',
								},
								{
									name: 'Leasehold',
									value: 'leasehold',
								},
								{
									name: 'Commonhold',
									value: 'commonhold',
								},
								{
									name: 'Shared Ownership',
									value: 'shared_ownership',
								},
								{
									name: 'Other',
									value: 'other',
								},
								{
									name: 'Unknown',
									value: 'unknown',
								},
							],
							type: 'select',
						},
					},
					{
						field: {
							id: 'uprn',
							inputWidth: 12,
							label: 'Are there conditions on the property such as those imposed by the First Homes Scheme or Section 157 restriction?',
							labelWidth: 12,
							type: 'yes-no-notes',
						},
					},
					{
						header: 'Leasehold',
						fields: [
							[
								{
									id: 'length_lease',
									inputWidth: 3,
									label: 'Length of lease',
									labelWidth: 3,
								},
								{
									id: 'start_date',
									inputWidth: 3,
									label: 'Start Date',
									labelWidth: 3,
								},
								{
									id: 'years_remaining',
									inputWidth: 3,
									label: 'Years Remaining',
									labelWidth: 3,
								},
								{
									id: 'ground_rent',
									inputWidth: 3,
									label: 'Ground Rent',
									labelWidth: 3,
								},
							],
						],
					},
				],
			},
		];

		return (<div className='container'>
			<div className='row'>
				<div className='col-12'>
					Hi Jason,<br /><br />
					This can be a welcome message from the agent.
					<br /><br />
					Thank you for choosing Diamond Properties.
					<br /><br />
					<h3>Instructions</h3>
					The form blah blah blah,
					<br /><br />
					<br /><br />
					<br /><br />
				</div>
				<div className='col-12'>
					{config.map((section, sectionNumber) => {
						return <div className="card" key={sectionNumber}>
							<div className="card-header">
								{section.name}
							</div>
							<div className="card-body">
								<div className='my-2'>
									{section.header}
								</div>
								{section.rows.map((row, rowNumber) => {

									if (isArray(row.fields)) {

										return <div key={rowNumber} className='py-4'>
											{!!row.header && <div className='my-2'>
												{sectionNumber+1}.{rowNumber+1} {row.header}
											</div>}

											{row.fields.map((field, fieldNumber) => {
												return <div className='row form-group bg-light' key={fieldNumber}>
													{field.map((input, inputNumber) => {
														return this.drawField(input, false, sectionNumber, rowNumber, fieldNumber, inputNumber);
													})}
												</div>;
											})}

										</div>;

									}

									return <div className='row form-group bg-light' key={rowNumber}>
										{this.drawField(row.field, true, sectionNumber, rowNumber, 0, 0)}
									</div>

								})}
							</div>
						</div>;
					})}
				</div>
			</div>
		</div>);
	}
}

export default PIQ;